import React from "react"
import { FacebookProvider, Page } from "react-facebook"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import esitekuvake from "../images/esitekuvake.jpg"

const AjankohtaistaPage = ({ location: { pathname } }) => (
  <Layout pathname={pathname}>
    <SEO title="Home" />
    <div className="row">
      <div className="spacer-50" />
      <div className="col-md-4">
        <h1>
          Pääsihteerin <strong className="accent-color">palsta</strong>
        </h1>
        <p>
          Suomen hautaustoiminnan keskusliitto ry toimii alan yhteisöjen
          keskusjärjestönä Suomessa. kehitämme hautaustoimintaa, edistämme
          alalla toimivien yhteistyötä ja vaalimme sekä edistämme
          hautauskulttuuria
        </p>
        {/* <p>
          <strong>
            Katso esitteemme{" "}
            <a
              href="http://www.esitteemme.fi/Hautaus/WebView/"
              target="_blank"
              rel="noopener noreferrer"
            >
              tästä
            </a>{" "}
            tai klikkaamalla kuvaa
          </strong>

          <a
            href="http://www.esitteemme.fi/Hautaus/WebView/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={esitekuvake} alt="Esite" />
          </a>
        </p> */}
      </div>
      <div className="col-md-8 flex justify-center">
        <FacebookProvider appId="338831073661381">
          <Page
            href="https://www.facebook.com/Suomen-Hautaustoiminnan-Keskusliitto-Ry-107307047294215"
            tabs="timeline"
            width={500}
            height={1000}
            adaptContainerWidth
          />
        </FacebookProvider>
        <div className="spacer-45" />
      </div>
    </div>
  </Layout>
)

export default AjankohtaistaPage
